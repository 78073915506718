import { BsFillLightningChargeFill, BsFullscreen } from "react-icons/bs";
import { AiOutlineFork } from "react-icons/ai";
import ChartGif from '../assets/chart.gif'
import DexViewIcon from "../components/DexViewIcon";

function Home() {
  return (
    <>
      <div className='main-bg'>
        <header className="container pb-2" style={{ height: '30%' }}>
          <div className="max-width-xwide text-align-left ml-md-4" style={{ height: '100%' }}>
            <h1 className="balance-text mb-1 color-white text-align-left text-bold">FutuAI - Next gen investment method
            </h1>
            <div className="post-excerpt mb-1 line-height-1.3 color-white text-align-left">
              FutuAI uses algorithms that integrate collection, prediction, analysis features by AI technology.
            </div>
            <a href="https://app.futuai.net/" className="btn btn-padded btn-dark btn-circle mr-0.5">LAUNCH APP</a>
            <a className="btn btn-empty color-white white-border" href="https://docs.futuai.net/">Read Documentation</a>
          </div>
        </header>
      </div>

      <section className="bg-fg-3 py-3">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div>
                <img className="m-sm-auto ml-md-2.5 mr-md-0" alt="chart.gif" src={ChartGif} width={'50%'} height={'50%'} />
              </div>
            </div>
            <div className="col-12 col-md-6 content no-col">
              <div className="medium-copy mb-1">More than 10 million data has been used to expand creativity and increase visibility into potential projects, generating more than 4 million investment advice every day. Technology will be available soon.</div>
              <a href="https://app.futuai.net/" className="btn btn-padded btn-dark btn-circle mr-0.5">LAUNCH APP</a>
              <a className="btn btn-empty color-primary primary-border" href="https://docs.futuai.net/">LEARN MORE</a>
            </div>
          </div>
        </div>
      </section>
      <div className="mt-2 mb-1">
        <section className="container text-md-center py-3">
          <div className="max-width-xxnarrow mx-md-auto">
            <h2 className="balance-text mx-auto mb-1 h1.5 balance-text">Start Investing with <br /> a simple advice request.</h2>
          </div>
          <a href="/" className="btn btn-padded btn-dark btn-circle mr-0.5">Get
            started</a>
          <a className="btn btn-empty color-primary primary-border" href="https://docs.futuai.net/">READ DOCUMENTATION</a>
          <pre className="page-api__pre-1 mx-auto mt-1 language-python"><code className="language-python text-left" style={{ padding: '22px 25px' }}>
            <span className="token" style={{ color: 'red' }}>service</span>
            {` Investment

FutuAI.advice.Crypto(
  Label="`}<span className="token punctuation" style={{ color: '#009c72' }}>ETH</span>{`",
  prompt="`}<span className="token punctuation" style={{ color: '#009c72' }}>Evaluate the potential of this project:</span>{`"
)`}</code></pre>
        </section>
      </div>

      <section className="container mb-3">
        <div className="row">

          <div className="col-12 col-md-4 mb-1.5">
            <h3 className="mb-1/3 d-flex"><span className="icon position-relative ml-n0.125 mr-0.25"
              style={{ marginTop: '-1px' }}><BsFillLightningChargeFill /></span>Fast</h3>
            <div className="content no-col medium-xsmall-copy line-height-1.5">Our advanced inference infrastructure
              provides extremely short response times.</div>
          </div>

          <div className="col-12 col-md-4 mb-1.5">
            <h3 className="mb-1/3 d-flex"><span className="icon position-relative ml-n0.125 mr-0.25"
              style={{ marginTop: '-1px' }}><BsFullscreen /></span>Accurate</h3>
            <div className="content no-col medium-xsmall-copy line-height-1.5">AI insights and judgments are learned
              from over 10 million outstanding data to give you the most accurate information.</div>
          </div>

          <div className="col-12 col-md-4 mb-1.5">
            <h3 className="mb-1/3 d-flex"><span className="icon position-relative ml-n0.125 mr-0.25"
              style={{ marginTop: '-1px' }}><AiOutlineFork /></span>Flexible</h3>
            <div className="content no-col medium-xsmall-copy line-height-1.5">Easy to use and flexible in making
              investment decisions anywhere at any time.</div>
          </div>

        </div>
      </section>

      <section className="bg-fg-3 py-3">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div style={{ maxWidth: '428px' }}>
                <h2 className="balance-text mb-1">
                  <span className="text-bold" style={{ textDecoration: 'underline' }}>Passport NFT</span> <br />
                  <span style={{ fontSize: '.8333333333rem' }}>Only users who have been involved in supporting the project since
                    its infancy with an NFT Passport can fully utilize our technologies.</span>
                </h2>
              </div>
            </div>
            <div className="col-12 col-md-6 content no-col">
              <div className="arrow-list-container medium-xsmall-copy">
                <p>We help investors use best advice and provide information, including:</p>

                <ul>
                  <li>Information</li>
                  <li>Scam red flag</li>
                  <li>Investment advice</li>
                </ul>

                <p>and many more functions with the goal of helping you become a smart investor with our AI product alone.</p>
              </div>
              <a href="/" className="btn btn-padded btn-dark btn-circle mr-0.5">Get
                started</a>
            </div>
          </div>
        </div>
      </section>

      <footer className="text-center" style={{ backgroundColor: '#FFF' }}>
        <div className="container pb-2">
          <div className="mx-auto max-width-wide col-12 pt-2">
            <h2 className="h1.5 balance-text mb-1.5">Partners</h2>
          </div>
          <div className='row partner-section'>
            <div className="col-6 col-md-3 mb-2 d-flex align-items-center">
              <img className='m-auto object-fit-contain' src={'https://img.futuai.net/img_1.jpg'} alt='partner-img' width={'40%'} />
            </div>
            <div className="col-6 col-md-3 mb-2 d-flex align-items-center">
              <a href="https://www.pinksale.finance/launchpad/0x4866800947AA11B98a64feE10722e74e19060918?chain=ETH">
                <img className='m-auto object-fit-contain' src={'https://img.futuai.net/img_2.jpg'} alt='partner-img' width={'40%'} />
              </a>
            </div>
            <div className="col-6 col-md-3 mb-2 d-flex align-items-center">
              <img className='m-auto object-fit-contain' src={'https://img.futuai.net/img_3.jpg'} alt='partner-img' width={'40%'} />
            </div>
            <div className="col-6 col-md-3 mb-2 d-flex align-items-center">
              <a href="https://www.dexview.com/" style={{width: '100%', height: '100%'}}>
                <DexViewIcon className='m-auto object-fit-contain'></DexViewIcon>
              </a>
            </div>
            <div className="col-6 col-md-4 mb-2 d-flex align-items-center">
              <img className='m-auto object-fit-contain' src={'https://img.futuai.net/img_4.jpg'} alt='partner-img' width={'35%'} />
            </div>
            <div className="col-6 col-md-4 mb-2 d-flex align-items-center">
              <img className='m-auto object-fit-contain' src={'https://img.futuai.net/img_5.jpg'} alt='partner-img' width={'35%'} />
            </div>
            <div className="col-6 col-md-4 mb-2 d-flex align-items-center">
              <img className='m-auto object-fit-contain' src={'https://img.futuai.net/img_6.jpg'} alt='partner-img' width={'35%'} />
            </div>

          </div>
        </div>
      </footer>
    </>
  );
}

export default Home;
