function Passport() {
  return (
    <>
      <div className='passport-bg'>

      </div>

      <section className="py-3" style={{ backgroundColor: '#EEEDEE' }}>
        <div className="container">
          <div className="row">
            <div className="col-5 m-auto">
              <div className="mb-1 medium-xsmall-copy">
                <h2 className="mb-1.5 mt-md-n0.1 text-bold">FutuAI Early Access Campaign</h2>
                <div className="mb-1.5 mt-md-n0.1">Starting today, you can join the allowlist to get AI NFT Passport.</div>
              </div>
              <div className="mb-1 medium-xsmall-copy">
                <h5 className="medium-xsmall-copy balance-text mb-1/12 fw-700">Step 1</h5>
                <div>
                  Join FutuAI community on Twitter.
                </div>
                <div>
                  <a href="https://twitter.com/futuAI_net" className="btn btn-padded btn-dark btn-circle mt-10/12">Click here to follow</a>
                </div>
              </div>


              <div className="mb-1 medium-xsmall-copy">
                <h5 className="medium-xsmall-copy balance-text mb-1/12 fw-700">Step 2</h5>
                <div>
                  Join FutuAI community on Discord.
                </div>
                <div>
                  <a href="https://discord.gg/hBneF5Um5F" className="btn btn-padded btn-dark btn-circle mt-10/12">@Discord channel</a>
                </div>
              </div>

              <div className="mb-1 medium-xsmall-copy">
                <h5 className="medium-xsmall-copy balance-text mb-1/12 fw-700">Step 3</h5>
                <div>
                  Join FutuAI community on Telegram.
                </div>
                <div>
                  <a href="https://t.me/FutuAI" className="btn btn-padded btn-dark btn-circle mt-10/12">@Telegram Group</a>
                </div>
              </div>

              <div className="mb-1 medium-xsmall-copy">
                <h5 className="medium-xsmall-copy balance-text mb-1/12 fw-700">Step 4</h5>
                <div>
                  Say something about FutuAI on Twitter.
                </div>
                <div>
                  <a className="btn btn-padded btn-dark btn-circle mt-10/12" href="https://twitter.com/intent/tweet?text=I participated in Early Access Campaign of @futuAI_net - provides next-gen investment method powered by AI technology. Go to http://futuai.net for more information.">Post in Twitter</a>
                </div>
              </div>

              <div className="mb-1 medium-xsmall-copy">
                <h5 className="medium-xsmall-copy balance-text mb-1/12 fw-700">Step 5</h5>
                <div>
                  Complete all the steps above and fill out this form.
                </div>
                <div>
                  <a href="https://docs.google.com/forms/d/1-nn_x5OIT4EEpnxXslb643x1AQppsECOcfBUANewWPc" className="btn btn-padded btn-dark btn-circle mt-10/12">Form</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Passport;
