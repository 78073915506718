
import { Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './styles/App.css';
import { BsDiscord, BsTwitter, BsTelegram } from "react-icons/bs";
import Home from './pages/Home';
import logoIcon from './assets/logo.svg';
import Passport from './pages/Passport';
import Dropdown from './components/Dropdown';

function App() {
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [provider, setProvider] = useState(window.ethereum);
  const [currentAccount, setCurrentAccount] = useState(null);

  const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(false);

  useEffect(() => {
    setProvider(detectProvider());
    initNavToggle();
    checkLocked();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (provider) {
      if (provider !== window.ethereum) {
        console.error(
          "Not window.ethereum provider.  Do you have multiple wallets installed ?"
        );
      }
      setIsMetaMaskInstalled(true);
    }
  }, [provider]);

  const detectProvider = () => {
    let provider;
    if (window.ethereum) {
      provider = window.ethereum;
    } else if (window.web3) {
      provider = window.web3.currentProvider;
    } else {
      console.warn("No Ethereum browser detected! Check out MetaMask");
    }
    return provider;
  };

  async function checkLocked() {
    const isUnlocked = await window?.ethereum?._metamask.isUnlocked();
    setIsConnected(isUnlocked);
    if (isUnlocked) {
      const accounts = await provider.request({
        method: "eth_requestAccounts",
      })
        .catch(err => {
          if (err.code === -32002) {
            window.alert(`Metamask Login window hasn't been cancelled.`)
          }
        });
      setCurrentAccount(accounts[0]);
    }
  }

  async function ConnectWallet() {
    if (!isMetaMaskInstalled) {
      window.open('https://metamask.io/');
      return;
    }
    if (isConnected) {
      return;
    }
    if (currentAccount) {
      // setIsConnecting(false);
      return;
    }
    if (!isConnecting) {
      setIsConnecting(true);
      await provider.request({
        method: "eth_requestAccounts",
      }).catch(err => {
        if (err.code === -32002) {
          window.alert(`Metamask Login window hasn't been cancelled.`)
        }
      });
    } else {
      provider.on("accountsChanged", handleAccountsChanged);
    };
  }

  useEffect(() => {
    if (!provider) return;
    if (isConnecting) {
      provider.on("accountsChanged", async (accounts) => {
        if (accounts.length > 0) {
          setIsConnecting(false);
          setIsConnected(true);
          setCurrentAccount(accounts[0]);
        }
      });
    } else {
      provider.removeListener("accountsChanged", () => { });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnecting])

  const handleAccountsChanged = async (accounts) => {
    if (accounts.length === 0) {
      onLogout();
    } else if (accounts[0] !== currentAccount) {
      setCurrentAccount(accounts[0]);
    }
  };


  useEffect(() => {
    if (isConnected && provider) {
      provider.on("accountsChanged", handleAccountsChanged);
    }

    return () => {
      if (isConnected && provider) {
        provider.removeListener("accountsChanged", handleAccountsChanged);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  const onLogout = () => {
    setIsConnected(false);
    setCurrentAccount(null);
  };

  function initNavToggle() {
    var navToggleElt = document.querySelector('.api-secondary-nav__toggle');
    var navContainerElt = document.querySelector('.api-secondary-nav');

    if (!navToggleElt || !navContainerElt) {
      return
    }

    navToggleElt.addEventListener('click', function () {
      navContainerElt.classList.toggle('api-secondary-nav--open');
    })
  }
  return <>
    <div className='page-api'>
      <header className="bg-white api-secondary-nav sticky bg-bg-70 backdrop-filter-blur">
        <nav className="nav" data-url="/api/">
          <div className="container">
            <div className="nav-row row align-items-start align-items-sm-center">
              <div className="col-2 col-sm nav-symbol-wrap">
                <a href="/"
                  className="nav-symbol"><img src={logoIcon} alt="Logo icon" height={'50px'} style={{ objectFit: 'contain' }} /></a>
              </div>
              <div className="col-9 col-sm small-caps">
                <ul className="d-none d-sm-flex flex-row align-items-center justify-content-between header-no-wrap">
                  <li className="ml-sm-1.5 ml-md-1.75" style={{ marginTop: '0.5px' }}>
                    <a className='btn' href='/'>OVERVIEW</a>
                  </li>
                  <li className="ml-sm-1.5 ml-md-1.75" style={{ marginTop: '0.5px' }}>
                    <a className='btn' href='/passport'>PASSPORT</a>
                  </li>
                  <li className="ml-sm-1.5 ml-md-1.75" style={{ marginTop: '0.5px' }}>
                    <a className='btn' href='https://www.pinksale.finance/launchpad/0x4866800947AA11B98a64feE10722e74e19060918?chain=ETH'>PRESALES</a>
                  </li>
                  <li className="ml-sm-1.5 ml-md-1.75" style={{ marginTop: '0.5px' }}>
                    <a className='btn' href='https://docs.futuai.net/'>DOCS</a>
                  </li>
                  <li className="ml-sm-1.5 ml-md-1.75" style={{ marginTop: '0.5px' }}>
                    <a className='btn' href='https://app.futuai.net/'>LAUNCH APP</a>
                  </li>
                  <li className="ml-sm-1.5 ml-md-1.75" style={{ marginTop: '0.5px' }}>
                    <a className='btn' href='https://app.futuai.net/mint-ai'>MINT AI</a>
                  </li>
                </ul>
                {/* <ul className="d-sm-none header-no-wrap" style={{ marginTop: '-1px' }}>
                  <li className="mb-0.25" style={{ marginTop: '0.5px' }}>
                    OVERVIEW
                  </li>
                  <li className="mb-0.25" style={{ marginTop: '0.5px' }}>
                    PASSPORT
                  </li>
                  <li className="mb-0.25" style={{ marginTop: '0.5px' }}>
                    PRESALES
                  </li>
                  <li className="mb-0.25" style={{ marginTop: '0.5px' }}>
                    DOCS
                  </li>
                  <li className="mb-0.25" style={{ marginTop: '0.5px' }}>
                    LAUNCH APP
                  </li>
                </ul> */}
              </div>
              <div className="col-2 col-sm small-caps">
                <ul className="d-none d-sm-flex flex-row align-items-center justify-content-between header-no-wrap">
                  {!isConnected ? <li className="" style={{ marginTop: '0.5px' }}>
                    <span className="btn btn-padded btn-dark btn-circle m-0"
                      onClick={ConnectWallet}>
                      {
                        isMetaMaskInstalled
                          ? (isConnecting ? "LOADING..." : (!isConnected ? 'CONNECT WALLET' : 'DISCONNECT'))
                          : "INSTALL METAMASK"
                      }
                    </span>
                  </li> : <Dropdown
                    title={`${currentAccount ? currentAccount.slice(0, 4) : '0x'}....${currentAccount && currentAccount.slice(-5, -1)}`}
                    onDisconnect={() => { }}
                  />}
                </ul>
                <ul className="d-sm-none header-no-wrap" style={{ marginTop: '-1px' }}>
                  {!isConnected ? <li className="mb-0.25" style={{ marginTop: '0.5px' }}>
                    <span className="btn btn-padded btn-dark btn-circle m-0"
                      onClick={ConnectWallet}>
                      {
                        isMetaMaskInstalled
                          ? (isConnecting ? "LOADING..." : (!isConnected ? 'CONNECT WALLET' : 'DISCONNECT'))
                          : "INSTALL METAMASK"
                      }
                    </span>
                  </li> : <Dropdown
                    title={`${currentAccount ? currentAccount.slice(0, 4) : '0x'}....${currentAccount && currentAccount.slice(-5, -1)}`}
                    onDisconnect={() => { }}
                  />}
                </ul>
              </div>
            </div>
          </div>
        </nav>

      </header>

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/passport' element={<Passport />} />
      </Routes>
    </div>

    <footer className="footer container medium-xsmall-copy line-height-1.6">
      <div className="row align-items-center mb-0.125">
        <div className="col-12 col-md mb-0.5">
          <a className="fade" style={{ marginTop: '1px' }} href="/">FutuAI &copy; 2023~</a>&emsp;<wbr />
        </div>
        <div className="col-12 col-md-2 col-lg-2 col-xl-1 mb-0.5">
          <div className="d-block d-md-none" style={{ fontSize: '1.333333rem' }}>
            <a className="fade icon"
              href="https://discord.gg/hBneF5Um5F"><BsDiscord size={30} />
            </a> &ensp;
            <a className="fade icon"
              href="https://t.me/FutuAI"><BsTelegram size={30} />
            </a> &ensp;
            <a className="fade icon"
              href="https://twitter.com/futuAI_net"><BsTwitter size={30} />
            </a>
          </div>
          <div className="d-none d-md-block" style={{ fontSize: '1.166667rem' }}>
            <div className="d-flex justify-content-between">
              <a className="fade icon"
                href="https://discord.gg/hBneF5Um5F"><BsDiscord size={30} />
              </a> &ensp;
              <a className="fade icon"
                href="https://t.me/FutuAI"><BsTelegram size={30} />
              </a> &ensp;
              <a className="fade icon"
                href="https://twitter.com/futuAI_net"><BsTwitter size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </>
}

export default App;
